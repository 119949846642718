body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.review .order-save.btn {
  display: inline-block;
  width: auto;
}

button.btn.btn-primary.disable-export {
  cursor: not-allowed;
  background-color: rgb(229, 229, 229) !important;
  pointer-events: none;
}

.moneydetails {
  flex-wrap: wrap;
  flex-direction: column-reverse;
}

.moneydetails .totalMoneyearn {
  padding: 12px 20px;
  height: auto;
  line-height: 1;
  font-size: 24px;
  width: fit-content;
}

.moneydetails .ipadrs .lblmoney {
  font-size: 18px;
}

.moneydetails .ipadrs {
  margin-bottom: 10px;
}
.btn-primary.viewupldbtn {
  padding-left: 20px !important;
  padding-right: 20px !important;
  min-width: inherit !important;
  margin-right: 0.5rem !important;
  margin-left: 0.5rem !important;
}

.creator-content-tab ul.react-tabs__tab-list {
  border: 0;
  margin: 0 0 20px;
}

.creator-content-tab ul.react-tabs__tab-list li {
  border: 2px solid #4C164D;
  padding: 8px 22px;
  border-radius: 50px;
  color: #4C164D;
  margin: 0;
  margin-right: 10px;
  bottom: 0;
}

.creator-content-tab ul.react-tabs__tab-list li.react-tabs__tab--selected {
  background: #4C164D;
  color: #fff;
}

.creator-content-tab ul.react-tabs__tab-list li.react-tabs__tab--selected:after, .creator-content-tab ul.react-tabs__tab-list li.react-tabs__tab--selected:before {
  height: 0;
}

.username { 
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.uao-hdr .btn {
  margin-top: .5rem;
  padding-left: 20px;
  padding-right: 20px;
}

.userinfo .val {
  padding-left: 0 !important;
}

.age-vrf-row .col-6 { width: 50%;}

.age-vrf-imgbox {
  padding: 0 0 56.25% !important;
  position: relative;
  margin-top: 15px;
}

.age-vrf-imgbox img {
  width: 100%;
  height: 100% !important;
  object-fit: cover !important;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  border: 1px solid #ccc;
  border-radius: 15px;
}

.creator-content-tab h4 {
  font-size: 18px;
}
.free-prof-row .card.tac {
  background: none;
}

.pageHeading {
  font-size: 26px !important;
}


@media (max-width:991px) {
  .pageHeading {
    font-size: 1.6rem;
  }

  .creator-content-tab ul.react-tabs__tab-list {
    display: flex;
    flex-wrap: nowrap;
    white-space: nowrap;
    overflow-x: auto;
    justify-content: flex-start;
}

}

@media (max-width:767px) {
  .d-flex.aic.jsb.flw.uao-hdr {
    justify-content: center !important;
  }
  .userinfo .val {
    padding-left: 0;
  }
  .userinfo .lbl { 
    width: 100%;
    margin-bottom: 5px;
  }
  .age-vrf-row .col-6 {
    width: 100%;
}
}