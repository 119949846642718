
@media (max-width:600px){
    .pagination-info{justify-content:center!important;flex-direction:column;}
}


@media (max-width:1199px) {
.toggle,.mob-logo{display:block;visibility:visible}
.logo{display:none}
#sidenav{position:fixed;z-index:999;top:0;left:-100%;transition:all .5s}
#sidenav.open{left:0}
.overlay.open{position:fixed;top:0;left:0;width:100%;height:100%}
}

/*All page CSS*/
@media (min-width:768px) {
.searchbar{margin-right:1.5rem}
}
.mh-50{min-height:calc(50% - 0.75rem)!important}
@media (max-width:1600px) {
.card-body h3{font-size:1rem!important}
.col-2{flex:0 0 auto;width:25%}    
}
@media (max-width:1450px) {
.col-6{width:100%}
}
@media (max-width:1300px){
    .col-3{flex:0 0 auto;width:33.33333333%}
}
@media (max-width:991px) {
.col-4,.col-8{flex:0 0 auto;width:100%}
.col-2{flex:0 0 auto;width:33.33333333%}   
.col-3{flex:0 0 auto;width:33.33333333%}    
}
@media(max-width:767px){
    .col-2{flex:0 0 auto;width:50%}
    .pageHeading{font-size:1.8rem;padding:0;}
    .row>.content-wrapper{padding-left: 0;padding-right: 0;}
     .content{padding: 0!important;}
    .pagination{margin-right:1rem;margin-left:1rem;}
    .viewupldbtn{margin-right:0!important;}
    .col-3{flex:0 0 auto;width:50%}
}
@media (max-width:640px) {
.userDetails{flex-direction:column;gap:2rem}
.loginbody{padding:1rem 2rem} 
.button_group .btn {width:calc(50% - 1rem);margin:0.5rem}
}
@media (max-width:540px) {
.loginDetails,.moneydetails{flex-direction:column;gap:2rem}
.val{font-size:16px}
.lbl{font-size:16px;width:100px}
.col-2{flex:0 0 auto;width:100%}  
.col-3{flex:0 0 auto;width:100%} 
 .button_group .btn {width:calc(100% - 1rem);margin:0.5rem}   
}





