html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font: inherit;
    vertical-align: baseline
}

*,
*::before,
*::after {
    box-sizing: border-box
}

.container {
    --bs-gutter-x: 1.5rem;
    --bs-gutter-y: 0;
    width: 100%;
    padding-right: calc(var(--bs-gutter-x) * 1);
    padding-left: calc(var(--bs-gutter-x) * 1);
    margin-right: auto;
    margin-left: auto
}

.row {
    --bs-gutter-x: 1.5rem;
    --bs-gutter-y: 1.5rem;
    display: flex;
    flex-wrap: wrap;
    margin-top: calc(-1 * var(--bs-gutter-y));
    margin-right: calc(-.5 * var(--bs-gutter-x));
    margin-left: calc(-.5 * var(--bs-gutter-x))
}

.row>* {
    flex-shrink: 0;
    width: 100%;
    max-width: 100%;
    padding-right: calc(var(--bs-gutter-x) * .5);
    padding-left: calc(var(--bs-gutter-x) * .5);
    margin-top: var(--bs-gutter-y)
}

.w-100 {
    width: 100%
}

.h-100 {
    height: 100%;
}

.jsb {
    justify-content: space-between !important
}

.jse {
    justify-content: flex-end !important
}

.aic {
    align-items: center !important
}

.d-flex {
    display: flex !important
}

.d-block {
    display: block !important;
}

.flw {
    -webkit-flex-wrap: wrap !important;
    -moz-flex-wrap: wrap !important;
    -ms-flex-wrap: wrap !important;
    -o-flex-wrap: wrap !important;
    flex-wrap: wrap !important;
}

.mx-auto {
    margin-right: auto !important;
    margin-left: auto !important
}

.mx-2 {
    margin-right: .5rem !important;
    margin-left: .5rem !important
}

.my-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important
}

.my-1 {
    margin-top: .25rem !important;
    margin-bottom: .25rem !important
}

.my-2 {
    margin-top: .5rem !important;
    margin-bottom: .5rem !important
}

.my-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important
}

.my-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important
}

.my-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important
}

.my-auto {
    margin-top: auto !important;
    margin-bottom: auto !important
}

.mt-1 {
    margin-top: .25rem !important
}

.mt-2 {
    margin-top: .5rem !important
}

.mt-3 {
    margin-top: 1rem !important
}

.mt-4 {
    margin-top: 1.5rem !important
}

.mt-5 {
    margin-top: 3rem !important
}

.me-1 {
    margin-right: .25rem !important
}

.me-2 {
    margin-right: .5rem !important
}

.me-3 {
    margin-right: 1rem !important
}

.me-4 {
    margin-right: 1.5rem !important
}

.me-5 {
    margin-right: 3rem !important
}

.mb-0 {
    margin-bottom: 0 !important;
}

.mb-1 {
    margin-bottom: .25rem !important
}

.mb-2 {
    margin-bottom: .5rem !important
}

.mb-3 {
    margin-bottom: 1rem !important
}

.mb-4 {
    margin-bottom: 1.5rem !important
}

.mb-5 {
    margin-bottom: 3rem !important
}

.ms-1 {
    margin-left: .25rem !important
}

.ms-2 {
    margin-left: .5rem !important
}

.ms-3 {
    margin-left: 1rem !important
}

.ms-4 {
    margin-left: 1.5rem !important
}

.ms-5 {
    margin-left: 3rem !important
}

.tac {
    text-align: center
}

.tar {
    text-align: right
}

.tal {
    text-align: left
}

.text-bold {
    font-weight: bold;
}

.pb-0 {
    padding-bottom: 0 !important;
}

.p-2 {
    padding: .5rem !important
}

.pb-4 {
    padding-bottom: 1.5rem !important
}

.pl-4 {
    padding-left: 1.5rem !important
}

.pr-4 {
    padding-right: 1.5rem !important
}

.unlist {
    list-style: none;
    padding: 0;
    margin: 0;
}

select {
    border: 1px solid #919AA3;
    border-radius: 7px;
    width: 140px;
    color: #919AA3;
    padding: 5px;
}

body {
    font-family: 'Gothic A1', sans-serif;
    color: #000;
    font-size: 16px;
}

.mlink {
    color: #919AA3;
    text-decoration: none;
    cursor: pointer;
}

/*Form controls*/
.form-label {
    font-size: .8rem;
    color: #343434;
    margin-bottom: .5rem;
    display: block
}

.form-control {
    display: block;
    width: 100%;
    padding: .5rem 1.5rem;
    font-size: .875rem;
    font-weight: 400;
    line-height: 2.5rem;
    color: #919AA3;
    background-color: transparent;
    background-clip: padding-box;
    border: 1px solid #919AA3;
    appearance: none;
    border-radius: 3rem;
    transition: .2s ease
}

.form-check-input {
    border: 1px solid #919AA3;
    width: 12px;
    height: 12px
}

.form-check-label {
    font-size: 14px
}

.form-link .popupbtn,
.form-link .linkbtn {
    color: #4C164D;
    font-size: 14px;
    cursor: pointer;
}

.form-link .divdr {
    margin: 0 10px;
    width: 1px;
    background: #4C164D
}

/*button*/
.btn {
    line-height: 40px;
    border: 0;
    border-radius: 50px;
    font-size: 14px;
    font-weight: 700;
    cursor: pointer;
    min-width: 120px;
    padding: 0 2.5rem;
    position: relative;
}

.btn-primary {
    background: #4C164D;
    color: #fff
}

.btn-primary:hover {
    background: #620064
}

.btn-green {
    background: #008053;
    color: #fff
}

.btn-green:hover {
    background: #009863
}

.btn-red {
    background: #AA0B25;
    color: #fff
}

.btn-red:hover {
    background: #cc1e3b
}

.btn-line {
    border: 1px solid #000;
    line-height: 15px;
    border-radius: 7px;
    color: #000;
    font-weight: 400;
    padding: 7px 10px;
    min-width: 120px;
    display: inline-block;
}

.btn-line:hover {
    background: #000;
    color: #fff;
}

.btn-lg {
    line-height: 58px;
}

.pageHeading {
    font-size: 2.5rem;
    font-weight: bold;
    padding: 0 1.5rem;
}

.pageTitle {
    font-weight: bold;
    font-size: 24px;
}

.pagination {
    border: 1px solid #919AA3;
    padding: .5rem;
    border-radius: 50px;
    background: #fff;
    margin-top: 15px;
    width: auto;
    text-align: center;
    margin-right: 2rem;
}

.pagination>span {
    border-radius: 20px;
    width: 20px;
    height: 20px;
    display: inline-flex;
    align-items: center;
    color: #000;
    margin: 0 .2rem;
    font-size: 12px;
    justify-content: center;
    cursor: pointer;
}

.pagination>span.active,
.pagination>span:hover {
    background: #4C164D;
    color: #fff !important
}

.pagination>span.next,
.pagination>span.prev {
    border: 1px solid #919AA3;
    color: #919AA3;
    font-size: 10px
}

.pageItems {
    font-size: 12px;
    color: #919aa3
}

.pagination-info .pagination {
    margin: 0;
}

footer {
    position: relative;
    z-index: 999;
}

.copyright {
    background: #000;
    color: #fff;
    font-size: 14px;
    text-align: center;
    padding: 40px 0
}

::-webkit-input-placeholder {
    /* Chrome/Opera/Safari */
    color: #919AA3;
    font-size: 12px;
}

::-moz-placeholder {
    /* Firefox 19+ */
    color: #919AA3;
    font-size: 12px;
}

:-ms-input-placeholder {
    /* IE 10+ */
    color: #919AA3;
    font-size: 12px;
}

:-moz-placeholder {
    /* Firefox 18- */
    color: #919AA3;
    font-size: 12px;
}

/*Popup*/
.popup {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1050;
    display: none;
    overflow: hidden;
    -webkit-overflow-scrolling: touch;
    outline: 0;
    background: rgba(76, 22, 77, 0.80)
}

.popup-dialog {
    width: 350px;
    margin: 10% auto;
    background: #fff;
    min-height: 350px;
    border-radius: 10px;
    box-shadow: 0 40px 45px 0 rgba(76, 22, 77, 0.10)
}

.popup-content {
    padding: 1rem
}

.close {
    width: 20px;
    height: 20px;
    border-radius: 20px;
    border: 1px solid #EDEDED;
    background: #fff;
    text-align: center;
    padding: 0;
    line-height: 20px;
    color: #343434;
    font-size: 10px;
    cursor: pointer
}

.popup-header {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    padding: 1rem 0;
    border-bottom: 1px solid #e9ecef;
    border-top-left-radius: .3rem;
    border-top-right-radius: .3rem
}

.popup-title {
    font-size: 14px;
    font-weight: 700
}

.popup-body p {
    padding: 1.5rem 0;
    font-size: 14px;
    line-height: 30px;
}

.pageLoader {
    width: 100%;
    height: 100%;
    position: fixed;
    background: rgb(76 22 77 / 80%);
    z-index: 999;
    display: none;
}

.ploader {
    -webkit-animation: rotate-center 1s linear infinite both;
    animation: rotate-center 1s linear infinite both;
    width: 120px;
    height: 120px;
    border: 16px solid #fff;
    border-top-color: rgba(255, 255, 255, 0.6);
    border-radius: 50%;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
    left: 50%
}

.btnLoader {
    position: absolute;
    right: 10px;
    top: 10px;
    -webkit-animation: rotate-center 1s linear infinite both;
    animation: rotate-center 1s linear infinite both;
    width: 20px;
    height: 20px;
    border: 4px solid #fff;
    border-top-color: rgba(255, 255, 255, 0.6);
    border-radius: 50%;
    display: none
}

.btn-lg .btnLoader {
    top: 18px
}

@-webkit-keyframes rotate-center {
    0% {
        -webkit-transform: rotate(0);
        transform: rotate(0)
    }

    100% {
        -webkit-transform: rotate(359deg);
        transform: rotate(359deg)
    }
}

@keyframes rotate-center {
    0% {
        -webkit-transform: rotate(0);
        transform: rotate(0)
    }

    100% {
        -webkit-transform: rotate(359deg);
        transform: rotate(359deg)
    }
}

/*Login Page*/
.main.bg-fill,
.bg-fill {
    background: #4C164D
}

main {
    min-height: calc(100vh - 98px)
}

.login_wrapper {
    flex: 0 0 auto;
    width: 98%;
    max-width: 560px;
    margin: 5rem 0
}

.loginbox {
    background: #fff;
    border-radius: 10px;
    padding: 1.8rem 0
}

form.loginbody a {
    font-size: 14px;
    text-decoration: none;
    color: #4C164D;
}

.loginLogo h3 {
    font-size: 24px;
    color: #343434;
    font-weight: 700;
    margin: 30px 0
}

.loginbody {
    padding: 1rem 3rem
}

/*Sidebar CSS*/
#sidenav-collapse-main {
    padding: 1rem .5rem
}

#sidenav {
    display: block;
    top: 0;
    bottom: 0;
    width: 100%;
    max-width: 300px !important;
    overflow-y: auto;
    padding: 0;
    box-shadow: none
}

.logo {
    border-bottom: 1px solid #623563;
    padding: 10px;
    height: 100%;
    background: #4c164d;
    min-width: 300px
}

.nav-link {
    color: #fff;
    text-decoration: none;
    font-size: 14px;
    border-bottom: 1px solid #623563;
    display: block;
    line-height: 45px;
    padding: 0 20px
}

.nav-link:hover,
.nav-link.active {
    background: rgba(255, 255, 255, 0.23)
}

.topbar {
    height: 100px;
    background: #fff;
    box-shadow: 0 10px 10px 0 rgba(76, 22, 77, 0.8);
    padding: 0
}

.signinUser {
    position: relative;
    min-width: 200px;
    padding: 15px;
    height: 120%
}

.signinUser i {
    color: #919AA3
}

.dropdown:hover .dropdown-menu {
    display: block
}

.dropdown-menu {
    display: none;
    position: absolute;
    padding: 1.5rem;
    background: #fff;
    width: 100%;
    border-radius: 10px;
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.09);
    top: 100%
}

.dropdown-menu:before {
    content: '';
    width: 0;
    height: 0;
    border-width: 0 5px 10px;
    border-style: solid;
    border-color: transparent transparent #fff;
    position: absolute;
    top: -10px;
    left: 40px
}

main {
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: flex;
    min-height: calc(100vh - 98px)
}

.main {
    background: #F7F7F7;
    flex: 1 1 auto;
    width: 100%
}

.mob-logo {
    visibility: hidden;
    background: #4c164d;
    height: 100%;
    padding: 15px
}

.toggle {
    cursor: pointer;
    font-size: 1.5rem;
    color: #919AA3;
    display: none
}

.toggle .fa-times,
.toggle.open .fa-bars {
    display: none
}

.toggle.open .fa-times {
    display: block
}

.menuToggle {
    position: relative;
    z-index: 999
}

/**/
/*All Page CSS*/
.content {
    padding: 0 1.5rem
}

.col-4 {
    flex: 0 0 auto;
    width: 33.333333%
}

.col-8 {
    flex: 0 0 auto;
    width: 66.666667%
}

.col-6 {
    flex: 0 0 auto;
    width: 50%
}

.card {
    position: relative;
    display: flex;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-color: #fff;
    background-clip: border-box;
    border-radius: 10px
}

.card img {
    max-width: 100%;
    display: block;
    height: auto
}

.card-header {
    padding: 1.3rem 1.3rem 0
}

.card-body {
    padding: 1.3rem
}

.card-footer {
    padding: 1.3rem
}

.searchbar {
    flex: 1 1 auto;
    max-width: 420px
}

.form-control.searchText {
    background: #fff;
    border-radius: 3rem 0 0 3rem;
    border-right: 0
}

button.searchbtn {
    background: #fff;
    border: 1px solid #919AA3;
    border-left: 0;
    border-radius: 0 3rem 3rem 0;
    padding: .5rem 1.5rem;
    cursor: pointer
}

.icon .icon-user {
    width: 100%;
    height: 100%;
    color: #4C164D;
    background: rgba(76, 22, 77, 0.09);
    display: flex;
    margin: 0 auto;
    justify-content: center;
    align-items: center;
    border-radius: 60%;
    overflow: hidden;
}

.icon-circle {
    background: rgba(76, 22, 77, 0.09);
    width: 50px;
    height: 50px;
    display: flex;
    margin-right: 1rem;
    justify-content: center;
    align-items: center;
    border-radius: 60%;
    padding: .2rem;
    position: relative;
}

.icon-lg {
    width: 120px;
    height: 120px
}

.icon-circle img {
    object-fit: cover;
    width: 100%;
    height: 100%
}

.icon_withdrawal {
    position: absolute;
    right: -10px;
    background: #AA0B25;
    width: 20px;
    height: 20px;
    text-align: center;
    line-height: 23px;
    color: #fff;
    border-radius: 20px;
    bottom: 3px
}

.icon_deposit {
    position: absolute;
    right: -10px;
    background: #008053;
    width: 20px;
    height: 20px;
    text-align: center;
    line-height: 23px;
    color: #fff;
    border-radius: 20px;
    bottom: 3px
}

.table-responsive {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch
}

.table {
    width: 100%;
    color: #000;
    vertical-align: middle;
    text-align: left;
    border-collapse: separate;
    border-spacing: 0 1rem
}

.table>tbody {
    vertical-align: inherit
}

.table>thead {
    vertical-align: bottom
}

.table thead th {
    font-size: 14px;
    font-weight: 700;
    padding: 0 1.5rem;
    white-space: nowrap
}

.table>tbody>tr>td {
    border-top: 1px solid #919AA3;
    vertical-align: middle;
    border-bottom: 1px solid #919AA3;
    padding: 10px 1.5rem;
    font-size: 14px;
    white-space: nowrap;
    font-weight: 600;
}

.table tbody td:first-child {
    border-left: 1px solid #919AA3;
    border-radius: 10px 0 0 10px;
    width: 30%
}

.table tbody td:last-child {
    border-right: 1px solid #919AA3;
    border-radius: 0 10px 10px 0
}

.iconbtn {
    border: 1px solid #919aa3;
    border-radius: 25px;
    width: 25px;
    height: 25px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    margin-left: .5rem;
    color: #919aa3;
    cursor: pointer
}

.iconbtn:hover {
    color: #000;
    border-color: #000
}

.lbl {
    color: #4C164D;
    width: 150px;
    display: inline-block;
    font-weight: 700;
    font-size: 18px
}

.val {
    font-size: 18px
}

.userinfo .val {
    padding-left: 1rem
}

.val .mlink {
    color: #000
}

.totalMoneyearn {
    background: #4C164D;
    color: #fff;
    font-size: 30px;
    border-radius: 10px;
    line-height: 60px;
    min-width: 150px;
    text-align: center;
    font-weight: 700
}

.lblmoney {
    font-size: 36px;
    color: #4C164D;
    font-weight: 700
}

.fa-circle {
    border: 1px solid;
    font-size: 12px;
    border-radius: 10px;
    padding: 3px
}

/*Dashboard*/
.dashboard .card-header {
    padding: 2.2rem
}

.dashboard .card-header h2 {
    font-size: 24px;
    font-weight: 700
}

.dashboard .card-body {
    padding: 2.2rem;
    padding-bottom: 1rem
}

.dashboard .card-body h3 {
    font-size: 1.2rem;
    color: #919AA3;
    height: 40px
}

.dashboard .card-footer {
    padding: 0;
}

.dashboard .card-footer .btn {
    background: #4C164D;
    display: block;
    border-radius: 0 0 10px 10px;
    line-height: 45px;
    color: #fff;
    padding-left: 2.2rem
}

.dashboard .icon-circle {
    background: rgba(76, 22, 77, 0.09);
    width: 100px;
    display: flex;
    margin: 0;
    justify-content: center;
    align-items: center;
    border-radius: 10px
}

.dashboard .icon-circle h4 {
    font-size: 24px;
    font-weight: 700;
    color: #4C164D;
    height: 50px;
    line-height: 55px;
}

.dashboard .icon {
    color: #919AA3
}

.dashboard .mh-50 {
    min-height: calc(50% - 0.75rem)
}

.col-2 {
    flex: 0 0 auto;
    width: 16.666667%
}

.review .card {
    padding: 10px
}

.review .btn {
    line-height: 28px;
    border: 0;
    border-radius: 50px;
    font-size: 14px;
    font-weight: 700;
    cursor: pointer;
    display: block;
    width: 100%
}

.username {
    font-size: 14px;
    font-weight: 700;
    color: #343434
}

.date {
    font-size: 10px;
    color: #919AA3
}

.review .card-body {
    padding: 1.2rem;
    border-top: 1px solid #E7E7E7
}

.review .icon-circle {
    margin: 0 auto;
}

.review .btnLoader {
    top: 4px;
    right: 4px
}

.col-3 {
    flex: 0 0 auto;
    width: 25%;
    max-width: 500px;
    padding: 0 15px !important;
    margin-top: 30px !important;
}

.card {
    position: relative;
    display: flex;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-color: #fff;
    background-clip: border-box;
    border-radius: 10px;
    overflow: hidden;
}

.card-body.lib-img,
.card-body.lib-video {
    padding: 0;
}

.card-body.lib-img img {
    max-width: 100%;
    display: block;
    height: auto;
    object-fit: cover;
}

.card-body.lib-video video {
    object-fit: cover;
}

.pageTitle {
    color: #919aa3;
}

.pageTitle span {
    color: #000;
    font-size: 24px;
}

.pagesubTitle {
    font-weight: bold;
}

.playbtn {
    background: #fff;
    border: 0;
    color: #ca4ab5;
    opacity: .8;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 50px;
    height: 50px;
    border-radius: 50px;
    cursor: pointer;
    z-index: 9999;
    display: none;
}

.playbtn.active {
    display: block;
}

.playbtn:hover {
    opacity: 1;
}

.loading .btnLoader {
    display: inline-flex;
}

.loading::after {
    content: '';
    position: absolute;
    right: 10px;
    top: 10px;
    -webkit-animation: rotate-center 1s linear infinite both;
    animation: rotate-center 1s linear infinite both;
    width: 20px;
    height: 20px;
    border: 4px solid #fff;
    border-top-color: rgba(255, 255, 255, 0.6);
    border-radius: 50%;
    margin-top: 8px;
}

.text_404 {
    font-size: 8rem !important;
    color: #4C164D !important;
    font-weight: 700;
    position: relative;
}

/*.text_404:before{content:attr(data-text);-webkit-animation:text-flicker-in-glow 5s infinite both;animation:text-flicker-in-glow 5s infinite both;position: absolute;left: 0;width: 100%;height: 100%;z-index: 9;color: #b37ab4;}*/
@-webkit-keyframes text-flicker-in-glow {
    0% {
        opacity: 0
    }

    10% {
        opacity: 0;
        text-shadow: none
    }

    10.1% {
        opacity: 1;
        text-shadow: none
    }

    10.2% {
        opacity: 0;
        text-shadow: none
    }

    20% {
        opacity: 0;
        text-shadow: none
    }

    20.1% {
        opacity: 1;
        text-shadow: 0 0 30px rgba(255, 255, 255, .25)
    }

    20.6% {
        opacity: 0;
        text-shadow: none
    }

    30% {
        opacity: 0;
        text-shadow: none
    }

    30.1% {
        opacity: 1;
        text-shadow: 0 0 30px rgba(255, 255, 255, .45), 0 0 60px rgba(255, 255, 255, .25)
    }

    30.5% {
        opacity: 1;
        text-shadow: 0 0 30px rgba(255, 255, 255, .45), 0 0 60px rgba(255, 255, 255, .25)
    }

    30.6% {
        opacity: 0;
        text-shadow: none
    }

    45% {
        opacity: 0;
        text-shadow: none
    }

    45.1% {
        opacity: 1;
        text-shadow: 0 0 30px rgba(255, 255, 255, .45), 0 0 60px rgba(255, 255, 255, .25)
    }

    50% {
        opacity: 1;
        text-shadow: 0 0 30px rgba(255, 255, 255, .45), 0 0 60px rgba(255, 255, 255, .25)
    }

    55% {
        opacity: 1;
        text-shadow: 0 0 30px rgba(255, 255, 255, .45), 0 0 60px rgba(255, 255, 255, .25)
    }

    55.1% {
        opacity: 0;
        text-shadow: none
    }

    57% {
        opacity: 0;
        text-shadow: none
    }

    57.1% {
        opacity: 1;
        text-shadow: 0 0 30px rgba(255, 255, 255, .55), 0 0 60px rgba(255, 255, 255, .35)
    }

    60% {
        opacity: 1;
        text-shadow: 0 0 30px rgba(255, 255, 255, .55), 0 0 60px rgba(255, 255, 255, .35)
    }

    60.1% {
        opacity: 0;
        text-shadow: none
    }

    65% {
        opacity: 0;
        text-shadow: none
    }

    65.1% {
        opacity: 1;
        text-shadow: 0 0 30px rgba(255, 255, 255, .55), 0 0 60px rgba(255, 255, 255, .35), 0 0 100px rgba(255, 255, 255, .1)
    }

    75% {
        opacity: 1;
        text-shadow: 0 0 30px rgba(255, 255, 255, .55), 0 0 60px rgba(255, 255, 255, .35), 0 0 100px rgba(255, 255, 255, .1)
    }

    75.1% {
        opacity: 0;
        text-shadow: none
    }

    77% {
        opacity: 0;
        text-shadow: none
    }

    77.1% {
        opacity: 1;
        text-shadow: 0 0 30px rgba(255, 255, 255, .55), 0 0 60px rgba(255, 255, 255, .4), 0 0 110px rgba(255, 255, 255, .2), 0 0 100px rgba(255, 255, 255, .1)
    }

    85% {
        opacity: 1;
        text-shadow: 0 0 30px rgba(255, 255, 255, .55), 0 0 60px rgba(255, 255, 255, .4), 0 0 110px rgba(255, 255, 255, .2), 0 0 100px rgba(255, 255, 255, .1)
    }

    85.1% {
        opacity: 0;
        text-shadow: none
    }

    86% {
        opacity: 0;
        text-shadow: none
    }

    86.1% {
        opacity: 1;
        text-shadow: 0 0 30px rgba(255, 255, 255, .6), 0 0 60px rgba(255, 255, 255, .45), 0 0 110px rgba(255, 255, 255, .25), 0 0 100px rgba(255, 255, 255, .1)
    }

    100% {
        opacity: 1;
        text-shadow: 0 0 30px rgba(255, 255, 255, .6), 0 0 60px rgba(255, 255, 255, .45), 0 0 110px rgba(255, 255, 255, .25), 0 0 100px rgba(255, 255, 255, .1)
    }
}

@keyframes text-flicker-in-glow {
    0% {
        opacity: 0
    }

    10% {
        opacity: 0;
        text-shadow: none
    }

    10.1% {
        opacity: 1;
        text-shadow: none
    }

    10.2% {
        opacity: 0;
        text-shadow: none
    }

    20% {
        opacity: 0;
        text-shadow: none
    }

    20.1% {
        opacity: 1;
        text-shadow: 0 0 30px rgba(255, 255, 255, .25)
    }

    20.6% {
        opacity: 0;
        text-shadow: none
    }

    30% {
        opacity: 0;
        text-shadow: none
    }

    30.1% {
        opacity: 1;
        text-shadow: 0 0 30px rgba(255, 255, 255, .45), 0 0 60px rgba(255, 255, 255, .25)
    }

    30.5% {
        opacity: 1;
        text-shadow: 0 0 30px rgba(255, 255, 255, .45), 0 0 60px rgba(255, 255, 255, .25)
    }

    30.6% {
        opacity: 0;
        text-shadow: none
    }

    45% {
        opacity: 0;
        text-shadow: none
    }

    45.1% {
        opacity: 1;
        text-shadow: 0 0 30px rgba(255, 255, 255, .45), 0 0 60px rgba(255, 255, 255, .25)
    }

    50% {
        opacity: 1;
        text-shadow: 0 0 30px rgba(255, 255, 255, .45), 0 0 60px rgba(255, 255, 255, .25)
    }

    55% {
        opacity: 1;
        text-shadow: 0 0 30px rgba(255, 255, 255, .45), 0 0 60px rgba(255, 255, 255, .25)
    }

    55.1% {
        opacity: 0;
        text-shadow: none
    }

    57% {
        opacity: 0;
        text-shadow: none
    }

    57.1% {
        opacity: 1;
        text-shadow: 0 0 30px rgba(255, 255, 255, .55), 0 0 60px rgba(255, 255, 255, .35)
    }

    60% {
        opacity: 1;
        text-shadow: 0 0 30px rgba(255, 255, 255, .55), 0 0 60px rgba(255, 255, 255, .35)
    }

    60.1% {
        opacity: 0;
        text-shadow: none
    }

    65% {
        opacity: 0;
        text-shadow: none
    }

    65.1% {
        opacity: 1;
        text-shadow: 0 0 30px rgba(255, 255, 255, .55), 0 0 60px rgba(255, 255, 255, .35), 0 0 100px rgba(255, 255, 255, .1)
    }

    75% {
        opacity: 1;
        text-shadow: 0 0 30px rgba(255, 255, 255, .55), 0 0 60px rgba(255, 255, 255, .35), 0 0 100px rgba(255, 255, 255, .1)
    }

    75.1% {
        opacity: 0;
        text-shadow: none
    }

    77% {
        opacity: 0;
        text-shadow: none
    }

    77.1% {
        opacity: 1;
        text-shadow: 0 0 30px rgba(255, 255, 255, .55), 0 0 60px rgba(255, 255, 255, .4), 0 0 110px rgba(255, 255, 255, .2), 0 0 100px rgba(255, 255, 255, .1)
    }

    85% {
        opacity: 1;
        text-shadow: 0 0 30px rgba(255, 255, 255, .55), 0 0 60px rgba(255, 255, 255, .4), 0 0 110px rgba(255, 255, 255, .2), 0 0 100px rgba(255, 255, 255, .1)
    }

    85.1% {
        opacity: 0;
        text-shadow: none
    }

    86% {
        opacity: 0;
        text-shadow: none
    }

    86.1% {
        opacity: 1;
        text-shadow: 0 0 30px rgba(255, 255, 255, .6), 0 0 60px rgba(255, 255, 255, .45), 0 0 110px rgba(255, 255, 255, .25), 0 0 100px rgba(255, 255, 255, .1)
    }

    100% {
        opacity: 1;
        text-shadow: 0 0 30px rgba(255, 255, 255, .6), 0 0 60px rgba(255, 255, 255, .45), 0 0 110px rgba(255, 255, 255, .25), 0 0 100px rgba(255, 255, 255, .1)
    }
}

.pageTitle.border-bottom {
    border-bottom: 1px solid;
    width: 100%;
    padding-bottom: 20px;
}

.pageTitle-2 {
    color: #000;
    font-size: 22px;
    font-weight: bold;
}

.load-more-btn {
    position: relative;
    background-color: #4C164D;
    color: #fff;
    text-decoration: none;
    padding: 10px 30px;
    border-radius: 50px;
    font-size: 14px;
    font-weight: 600;
    display: table;
    margin-left: auto;
    margin-right: auto;
    margin-top: 17px;
}

/* Safari */
@-webkit-keyframes spin {
    0% {
        -webkit-transform: rotate(0deg);
    }

    100% {
        -webkit-transform: rotate(360deg);
    }
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

.btn-loader {
    content: '';
    border: 4px dotted #fff;
    border-radius: 50%;
    width: 18px;
    height: 18px;
    border-width: 4px;
    -webkit-animation: spin 2s linear infinite;
    animation: spin 2s linear infinite;
    display: inline-block;
    margin-right: 8px;
    vertical-align: middle;
}